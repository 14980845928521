<template>
  <div class="footerBox">
    <a-row class="firstWrap">
      <a-col :span="6" class="firstFooter">
        <a href="https://uviewui.com/" target="_blank">
          <img :src="logoImg" alt="图标加载失败">
        </a>
      </a-col>
      <a-col :span="6" class="footerContent">
        <div><h2 class="cor">友情链接</h2></div>
        <div><a href="https://beian.miit.gov.cn">工信部</a></div>
        <div><a href="http://www.chinatip.com.cn/">信思哲官网</a></div>
      </a-col>
    </a-row>
    <a-row class="lastFooter">
      <a-col :span="24">
        Copyright {{ new Date().getFullYear() }} 信思哲智库 | <a href="https://beian.miit.gov.cn">京ICP备15054885号-2</a>
        <div style="width:300px;margin:0 auto; padding:20px 0">
          <img :src="backImg" alt="" srcset="" style="height: 20px;line-height: 20px">
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041431"
             style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
            <p style="height:20px;line-height:20px;margin: 0 0 0 5px; color:#939393;">京公网安备11010802041431号</p>
          </a>
        </div>
      </a-col>
    </a-row>
  </div>

</template>

<script>
import logoImg from "@/assets/logo.png"
import backImg from "@/assets/batb.png";

export default {
  name: 'Footer',
  data() {
    return {
      logoImg,
      backImg
    }
  }
}
</script>

<style lang="scss" scoped>
.footerBox {
  background: #000;

  .firstWrap {
    height: 150px;
    border: 1px solid hsla(0, 0%, 100%, .25);

    > div {
      height: 100%;
    }

    .firstFooter {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footerContent { //中间内容区域
      color: #fff;
      padding-top: 20px;

      > a {
        font-size: 20px;
        font-weight: 600;
      }

      > div {
        margin: 12px;
      }
    }

    .focusUs { //关注我们
      color: #fff;
    }
  }

  .lastFooter {
    color: #fff;
    height: 30px;
    line-height: 30px;
    text-align: center;
  }
}

a {
  text-decoration: none;
  color: #FFFFFF;
  text-align: center;
}

.cor {
  color: white;
  cursor: pointer;
}
</style>
