<template>
  <div>
    <a-row>
      <a-col :span="4" class="content-center center" @click="changeTitle({key:'homePage'})">
        <img src="@/assets/logo.png" class="img-Logo"/>
        <div>
          信思哲智库微调查
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name:'Header',
    data(){
      return{
        searchValue:'',//搜索内容
        current: ['homePage'],// homePage首页  newsList 资讯新闻列表
      }
    },
    methods:{
      onSearch(){
        this.$message.success('触发搜索，搜索内容为：'+ this.searchValue);
      },
      changeTitle(item){
        if(item.key != this.$route.name){
          this.$router.push(item.key)
        }
      }
    },
    created(){
      let routeKsy = sessionStorage.getItem('routeKsy')
      if(routeKsy){
        this.current = [routeKsy]
      }
    }
  }
</script>

<style scoped="scoped" lang="scss">
  .content-center{
    font-weight: 700;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    >div{
      font-size: 22px;
      color: #2c3e50;
    }
    .img-Logo{
      width: 35px;
      height: 35px;
      vertical-align:center;
    }
  }
  //我们隐藏了 antdvue 导航条的底部线条 并且给了新的高度
  .ant-menu-horizontal{
    border: none;
    line-height: 61px;
  }
  .center{
    text-align: center;
  }
</style>
